<template>
  <div>
    <navbar
      @toggle-drawer="$refs.drawer.drawer = !$refs.drawer.drawer"
    ></navbar>
    <sidenav ref="drawer"></sidenav>
    <v-container
      ><v-row justify="center">
        <v-col xl="6" lg="8" md="10" sm="11" cols="12">
          <v-card elevation="2">
            <v-card-text v-if="user" class="text-center">
              <v-row
                ><v-col cols="12" class="text_field_col"
                  ><h2 data-cy="text_myAccount" style="color: #0090a4">
                    Notification Settings
                  </h2>
                </v-col></v-row
              >
              <v-card>
                <v-card-text>
                  <v-row
                    ><v-tabs class="table_tabs" v-model="selected_tab">
                      <v-tab data-cy="btn_myAccount_userSettings"
                        >Frequency</v-tab
                      >
                      <v-tab>Cameras</v-tab>
                    </v-tabs></v-row
                  >
                  <v-row class="mt-1 pt-3" v-if="selected_tab == 0">
                    <v-col>
                      <v-data-table
                        loading-text="Loading... Please wait"
                        :headers="headers_notifications"
                        :items="notification_preference"
                        :items-per-page="3"
                        class="elevation-0"
                        hide-default-footer
                      >
                        <template v-slot:[`item.title`]="{ item }">
                          <span>
                            {{
                              item.title.charAt(0).toUpperCase() +
                              item.title.slice(1)
                            }}
                          </span>
                        </template>
                        <template v-slot:[`item.email`]="{ item }">
                          <span style="width: 160px; display: inline-block"
                            ><v-select
                              v-model="item.email_frequency"
                              hide-details
                              :items="notification_freq_items"
                              dense
                              rounded
                              outlined
                              full-width
                              class="assign_select my-3"
                            ></v-select
                          ></span>
                        </template>
                        <template v-slot:[`item.sms`]="{ item }">
                          <span style="width: 160px; display: inline-block"
                            ><v-select
                              v-model="item.sms_frequency"
                              hide-details
                              :items="notification_freq_items"
                              dense
                              rounded
                              outlined
                              full-width
                              class="assign_select my-3"
                            ></v-select
                          ></span>
                        </template>
                        <template v-slot:[`item.call`]="{ item }">
                          <span style="width: 160px; display: inline-block"
                            ><v-select
                              v-model="item.call_frequency"
                              hide-details
                              :items="notification_freq_items"
                              dense
                              rounded
                              outlined
                              full-width
                              class="assign_select my-3"
                            ></v-select
                          ></span>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                  <v-row class="mt-1 pt-3" v-else-if="selected_tab == 1">
                    <v-col cols="12">
                      <v-data-table
                        loading-text="Loading... Please wait"
                        :headers="headers_camera_notifications"
                        :items="cameras"
                        :items-per-page="100"
                        class="elevation-0"
                        hide-default-footer
                      >
                        <template
                          v-slot:[`item.calving_notifications_enabled`]="{
                            item,
                          }"
                        >
                          <span style="width: 110px; display: inline-block"
                            ><v-select
                              v-model="item.calving_notifications_enabled"
                              hide-details
                              :items="cameras_notification_items"
                              dense
                              rounded
                              outlined
                              full-width
                              class="assign_select my-3"
                            ></v-select
                          ></span>
                        </template>
                        <template
                          v-slot:[`item.estrus_notifications_enabled`]="{
                            item,
                          }"
                        >
                          <span style="width: 110px; display: inline-block"
                            ><v-select
                              v-model="item.estrus_notifications_enabled"
                              hide-details
                              :items="cameras_notification_items"
                              dense
                              rounded
                              outlined
                              full-width
                              class="assign_select my-3"
                            ></v-select
                          ></span>
                        </template>
                        <template
                          v-slot:[`item.predator_notifications_enabled`]="{
                            item,
                          }"
                        >
                          <span style="width: 110px; display: inline-block"
                            ><v-select
                              v-model="item.predator_notifications_enabled"
                              hide-details
                              :items="cameras_notification_items"
                              dense
                              rounded
                              outlined
                              full-width
                              class="assign_select my-3"
                            ></v-select
                          ></span>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              <v-col cols="12" class="pt-0 pb-0 pr-0">
                <v-row>
                  <v-col v-if="selected_tab != 3" class="text-right">
                    <v-btn
                      @click="save()"
                      class="save_btn ml-4"
                      color="primary"
                      :disabled="!made_changes"
                      data-cy="btn_save"
                      >Save</v-btn
                    ></v-col
                  >
                </v-row>
              </v-col>
            </v-card-text>
          </v-card></v-col
        >
      </v-row>
    </v-container>
  </div>
</template>

<script>
import navbar from "@/components/Navbar.vue";
import sidenav from "@/components/SideNav.vue";
import axios from "axios";

//import { isEqual } from "lodash";
import * as hasura_mutations from "../graphql_hasura/mutations";

import { eventBus } from "../main.js";

export default {
  name: "UserSettings",
  components: {
    navbar,
    sidenav,
  },
  props: ["setting"],
  data() {
    return {
      selected_tab: 0,
      stepperCount: 1,
      email_pr: "",
      verificationCode: "",
      currentPasswordVisible: false,
      newPasswordVisible: false,
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",

      error: null,

      cameras_notification_items: [{value: true, text: "Yes"}, {value: false, text: "No"}],

      notification_preference: [
        {
          call_frequency: "NEVER",
          email_frequency: "NEVER",
          sms_frequency: "NEVER",
          title: "alert",
        },
        {
          call_frequency: "NEVER",
          email_frequency: "NEVER",
          sms_frequency: "NEVER",
          title: "warning",
        },
        {
          call_frequency: "NEVER",
          email_frequency: "NEVER",
          sms_frequency: "NEVER",
          title: "info",
        },
      ],
      notification_freq_items: [
        { value: "NEVER", text: "Never" },
        { value: "FIVE_MINUTES", text: "5 min" },
        { value: "FIFTEEN_MINUTES", text: "15 min" },
        { value: "THIRTY_MINUTES", text: "30 min" },
        { value: "ONE_HOUR", text: "1 hrs" },
        { value: "TWO_HOURS", text: "2 hrs" },
        { value: "FOUR_HOURS", text: "4 hrs" },
        { value: "EIGHT_HOURS", text: "8 hrs" },
        { value: "TWELVE_HOURS", text: "12 hrs" },
        { value: "ONE_DAY", text: "1 day" },
      ],
      cameras: [],
    };
  },
  computed: {
    cameras_equal() {
      var changed_cams = [[], []];
      if (!this.cameras.length || !this.cameras_initial.length) {
        return false;
      }
      for (const i in this.cameras_initial) {
        let check_diff = this.object_equal(
          this.cameras_initial[i],
          this.cameras[i]
        );
        if (check_diff) {
          changed_cams[0].push(this.cameras_initial[i].id);
          changed_cams[1].push(check_diff);
        }
      }
      if (changed_cams[0].length) {
        return changed_cams;
      }
      return false;
    },
    customer_id() {
      return this.$store.getters.getCustomerID;
    },
    notification_preference_to_object() {
      var notification_preference_object = {};
      for (const i in this.notification_preference) {
        notification_preference_object[
          this.notification_preference[i].title + "_call_frequency"
        ] = this.notification_preference[i].call_frequency;
        notification_preference_object[
          this.notification_preference[i].title + "_email_frequency"
        ] = this.notification_preference[i].email_frequency;
        notification_preference_object[
          this.notification_preference[i].title + "_sms_frequency"
        ] = this.notification_preference[i].sms_frequency;
      }
      return notification_preference_object;
    },
    made_changes() {
      return (
        this.object_equal(
          this.notification_preference_initial,
          this.notification_preference_to_object
        ) || this.cameras_equal
      );
    },

    user() {
      return this.$store.getters.getUser.attributes;
    },

    notification_preference_initial() {
      return this.$store.getters.getNotificationPreference;
    },
    headers_notifications() {
      return [
        {
          text: "Notification",
          value: "title",
        },
        {
          text: "Email",
          value: "email",
        },
        {
          text: "SMS",
          value: "sms",
        },
        // {
        //   text: "Call",
        //   value: "call",
        // },
      ];
    },
    headers_camera_notifications() {
      return [
        {
          text: "Camera",
          value: "name",
        },
        {
          text: "Calving",
          value: "calving_notifications_enabled",
          width: "90px",
        },
        {
          text: "Estrus",
          value: "estrus_notifications_enabled",
          width: "60px",
        },
        {
          text: "Predator",
          value: "predator_notifications_enabled",
          width: "90px",
        },
      ];
    },

    cameras_initial() {
      return this.$store.getters.getCameraObjects;
    },
  },
  methods: {
    object_equal(original, updated) {
      var diff_object = {};
      for (const i in original) {
        if (original[i] != updated[i]) {
          diff_object[i] = updated[i];
        }
      }
      if (JSON.stringify(diff_object) === "{}") {
        return false;
      }
      return diff_object;
    },

    async execute_query(type) {
      if (type == "notification_preference") {
        const response = await axios.post(
          this.$store.getters.getHasuraParamsWrite.url,
          {
            query: hasura_mutations.update_notification_preference_by_pk,
            variables: {
              pk_columns: { customer_id: this.customer_id },
              _set: this.object_equal(
                this.notification_preference_initial,
                this.notification_preference_to_object
              ),
            },
          },
          {
            headers: this.$store.getters.getHasuraParamsWrite.headers,
          }
        );
        if (response.errors || response.data.errors) {
          throw new Error("GraphQL error: " + response.errors[0].message);
        }
        return response.data;
      } else if (type == "cameras") {
        for (const c in this.cameras_equal[0]) {
          const response = await axios.post(
            this.$store.getters.getHasuraParamsWrite.url,
            {
              query: hasura_mutations.update_camera_by_pk,
              variables: {
                pk_columns: { id: this.cameras_equal[0][c] },
                _set: this.cameras_equal[1][c],
              },
            },
            {
              headers: this.$store.getters.getHasuraParamsWrite.headers,
            }
          );

          if (response.errors || response.data.errors) {
            throw new Error("GraphQL error: " + response.errors[0].message);
          }
        }

        return "success";
      }
    },

    async save() {
      let all_queries = [];
      if (this.cameras_equal) {
        all_queries.push(this.execute_query("cameras"));
      }
   
      if (
        this.object_equal(
          this.notification_preference_initial,
          this.notification_preference_to_object
        )
      ) {
        all_queries.push(this.execute_query("notification_preference"));
      }
      const results = await Promise.allSettled(all_queries);

      const hasError = results.some((result) => result.status === "rejected");

      if (hasError) {
        eventBus.$emit("add_button_alert", {
          msg: `Error updating notifications`,
          err: true,
        });
        this.$store.dispatch("DDB_GET_CAMERAS");
      } else {
        this.$store.dispatch("DDB_GET_CUSTOMER", false);
        this.$store.dispatch("DDB_GET_CAMERAS");
        eventBus.$emit("add_button_alert", {
          msg: `Successfully updated notifications`,
          err: false,
        });
      }
    },

  },
  watch: {
    customer_id() {
      this.$store.dispatch("DDB_GET_CAMERAS");
    },
    cameras_initial() {
      this.cameras = JSON.parse(
        JSON.stringify(this.$store.getters.getCameraObjects)
      );
    },
    notification_preference_initial() {
      if (this.notification_preference_initial) {
        var temp_notification_preference = [
          {
            call_frequency: "NEVER",
            email_frequency: "NEVER",
            sms_frequency: "NEVER",
            title: "alert",
          },
          {
            call_frequency: "NEVER",
            email_frequency: "NEVER",
            sms_frequency: "NEVER",
            title: "warning",
          },
          {
            call_frequency: "NEVER",
            email_frequency: "NEVER",
            sms_frequency: "NEVER",
            title: "info",
          },
        ];
        let level_index = { alert: 0, warning: 1, info: 2 };
        for (const i in this.notification_preference_initial) {
          temp_notification_preference[level_index[i.split("_")[0]]][
            i.split("_")[1] + "_frequency"
          ] = this.notification_preference_initial[i];
        }
        this.notification_preference = temp_notification_preference;
      }
    },
    address_equal() {
      if (
        !this.address_equal &&
        this.physical_address &&
        this.mailing_address
      ) {
        this.same_address = false;
      }
    },
    notification_preferenc: {
      handler: function () {
        this.notification_address_equal_test();
      },
      deep: true,
    },
  },

  async mounted() {
    if (this.customer_id) {
      this.$store.dispatch("DDB_GET_CAMERAS");
    }

    if (this.cameras_initial.length) {
      this.cameras = JSON.parse(
        JSON.stringify(this.$store.getters.getCameraObjects)
      );
    }

    if (this.notification_preference_initial) {
      var temp_notification_preference = [
        {
          call_frequency: "NEVER",
          email_frequency: "NEVER",
          sms_frequency: "NEVER",
          title: "alert",
        },
        {
          call_frequency: "NEVER",
          email_frequency: "NEVER",
          sms_frequency: "NEVER",
          title: "warning",
        },
        {
          call_frequency: "NEVER",
          email_frequency: "NEVER",
          sms_frequency: "NEVER",
          title: "info",
        },
      ];
      let level_index = { alert: 0, warning: 1, info: 2 };
      for (const i in this.notification_preference_initial) {
        temp_notification_preference[level_index[i.split("_")[0]]][
          i.split("_")[1] + "_frequency"
        ] = this.notification_preference_initial[i];
      }
      this.notification_preference = temp_notification_preference;
    }
  },
};
</script>

<style lang="css">
.non_placed_hardware {
  cursor: initial;
}

.map_item_content_false {
  cursor: pointer !important;
}
.map_item_content_true {
  color: gray;
}
.map_side_bar .v-list-item {
  cursor: move;
}

.map_side_bar {
  overflow-y: scroll;
}
.settings_card_title {
  background-color: #ff9900;
}
.text_field_col {
  text-align: left;
}
.text_field_title {
  font-weight: bold;
}
.setting_tab {
  font-weight: bold;
  font-size: 14px;
}
.val_error {
  color: red;
}
</style>
